<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/tabela-de-precos"
  >
    <h4 class="text-center">{{ this.form.codigo_grupo_preco }}</h4>
    <LineDivider></LineDivider>
    <v-row v-if="mode == 'cep'">
      <v-col cols="12" md="5" class="py-0">
        <FormField
          label="CEP Inicial"
          v-model="form.cep_inicial"
          typeField="number"
          mask="#####-###"
        />
      </v-col>
      <v-col cols="12" md="5" class="py-0">
        <FormField
          label="CEP Final"
          v-model="form.cep_final"
          typeField="number"
          mask="#####-###"
        />
      </v-col>
      <v-col cols="12" md="2" class="py-0">
        <FormButton full text="Adicionar Faixa" @click="adicionarFaixaCep()" />
      </v-col>
      <v-col cols="12" class="mb-5" v-if="form.faixasCep.length > 0">
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">CEP Inicial</th>
              <th class="text-center">CEP Final</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(faixa, i) in form.faixasCep" :key="i">
              <td class="text-center">{{ maskCep(faixa.inicial) }}</td>
              <td class="text-center">{{ maskCep(faixa.final) }}</td>
              <td class="text-center">
                <FormButton
                  text="Excluir"
                  type="error"
                  @click="removerFaixaCep(faixa)"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <LineDivider></LineDivider>
      </v-col>
    </v-row>
    <v-row v-if="mode == 'cliente'">
      <v-col>
        <DataTable
          :headers="headersClient"
          :endpoint="endpointClient"
          sortBy="cpf_cnpj"
          :sortDesc="false"
          search
          classe="elevation-0"
        >
        </DataTable>
      </v-col>
    </v-row>
    <v-row v-if="mode == 'produto'">
      <v-col>
        <DataTable
          :headers="headersProduct"
          :endpoint="endpointProduct"
          :filters="filtersProduct"
          noHideFilters
          :auto-search="false"
          sortBy="nome"
          :sortDesc="false"
          ref="datatable"
          classe="elevation-0"
        >
          <template v-slot:disponivelvenda="{ row }">
            <FormCheckbox
              v-model="row.disponivelvenda"
              class="my-0 py-0 pt-2 mb-n5"
              @change="updatePajeAvailability(row)"
              :disabled="disableCheckbox(row)"
            />
          </template>
          <template v-slot:nome="{ row }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-if="row.tipounidade == 'UN'"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  color="info"
                  label
                  small
                >
                  <v-icon size="18">
                    fa-barcode
                  </v-icon>
                </v-chip>
                <v-chip
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  label
                  small
                >
                  <v-icon size="18">
                    fa-balance-scale
                  </v-icon>
                </v-chip>
              </template>
              <span>{{
                row.tipounidade == 'UN'
                  ? 'Produto vendido em unidades'
                  : 'Produto pesado'
              }}</span>
            </v-tooltip>
            {{ row.nome }}
            {{ row.tipo_produto == 'CONJUNTO' ? '(CONJUNTO)' : '' }}
            <small class="float-right" v-if="row.codigobarras">{{
              row.codigobarras
            }}</small>
          </template>
          <template v-slot:precovenda="{ row }">
            <FormField
              v-model.number="row.precovenda"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateRemotePrices(row)"
            />
          </template>
          <template v-slot:precovendaatacado="{ row }">
            <FormField
              v-model.number="row.precovendaatacado"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateRemotePrices(row)"
            />
          </template>
          <template v-slot:minvendaatacado="{ row }">
            <FormField
              v-model.number="row.minvendaatacado"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateRemotePrices(row)"
            />
          </template>
          <template v-slot:maxporpedido="{ row }">
            <FormField
              v-model.number="row.maxporpedido"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateRemotePrices(row)"
            />
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <template v-slot:buttons>
      <FormButton v-if="mode == 'cep'" text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import FormCheckbox from '@/components/form/FormCheckbox'
import DataTable from '@/components/DataTable'
import { mapGetters } from 'vuex'
import {
  TABELA_PRECO_UPDATE_PRECOS,
  TABELA_PRECO_UPDATE_DISPONIVEL_VENDA
} from '@/store/actions/tabelaPreco'
import {
  GRUPO_PRECO_VALIDA_CEP,
  GRUPO_PRECO_SALVAR_CEP,
  GRUPO_PRECO_LOAD_FAIXA_CEP
} from '../../../store/actions/grupoPreco'

export default {
  name: 'TabelaPrecoForm',
  components: {
    SidePopup,
    LineDivider,
    FormButton,
    FormField,
    FormCheckbox,
    DataTable
  },
  data: () => ({
    mode: 'cliente',
    isLoading: false,
    form: {
      faixasCep: [],
      regiaoSelecionada: {
        idareavendafornecedor: ''
      }
    },
    loadingRow: {},
    headersClient: [
      { text: 'Nome / Razão social', value: 'name' },
      { text: 'CPF / CNPJ', value: 'cpf_cnpj' }
    ],
    headersProduct: [
      { text: 'Disponível', value: 'disponivelvenda' },
      { text: 'Produto', value: 'nome' },
      { text: 'Código interno', value: 'codigointerno', align: 'center' },
      { text: 'Preço varejo', value: 'precovenda' },
      { text: 'Preço atacado', value: 'precovendaatacado' },
      { text: 'Min. atacado', value: 'minvendaatacado' },
      { text: 'Max. no pedido', value: 'maxporpedido' }
    ],
    filtersProduct: [
      { label: 'Pesquisar', query: 'textSearch', cols: 'auto' },
      { label: 'Código de barras', query: 'codbarras', cols: 'auto' },
      { label: 'Código interno', query: 'codinterno', cols: 'auto' },
      { label: 'Agrupador de embalagem', query: 'agrupador', cols: 'auto' },
      {
        label: 'Tipo de cadastro',
        query: 'tipoCadastro',
        default: 'todos',
        options: [
          { text: 'Todos', value: 'todos' },
          { text: 'Com código interno', value: 'codigointerno' },
          { text: 'Com código de barras', value: 'codigobarras' }
        ],
        cols: 'auto',
        clearable: 'false'
      }
    ]
  }),
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/tabela-de-precos')
      }
    },
    onSubmit() {
      if (this.mode == 'cep') {
        this.isLoading = true
        this.$store
          .dispatch(GRUPO_PRECO_SALVAR_CEP, {
            codigoGrupo: this.form.codigo_grupo_preco,
            faixas: this.form.faixasCep
          })
          .then(() => {
            this.$vueOnToast.pop('success', 'Faixas de CEP salvas com sucesso')
            this.onClose(true)
          })
          .catch(err => {
            const erro =
              err.response.data.Mensagem ||
              err.response.data ||
              'Erro ao salvar faixas de CEP'
            this.$vueOnToast.pop('error', erro)
            this.isLoading = false
          })
      }
    },
    getDataFaixaCep(codigo_grupo_preco) {
      this.isLoading = true
      this.$store
        .dispatch(GRUPO_PRECO_LOAD_FAIXA_CEP, codigo_grupo_preco)
        .then(data => {
          this.form.faixasCep = data.faixas_cep || []
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    maskCep(cep) {
      const cepAddress = String(cep).replace(/[^0-9-]/g, '')
      const numericCep = cepAddress.replace(/\D/g, '')
      const limitedCep = numericCep.substring(0, 8)
      return this.formatCep(limitedCep)
    },
    formatCep(limitedCep) {
      if (limitedCep.length >= 8) {
        const formattedCep = limitedCep.replace(/(\d{5})(\d{3})$/, '$1-$2')
        return formattedCep
      }
      return limitedCep
    },
    cepValidation(cep) {
      return !cep || cep.length !== 8
    },
    clearCep(cep) {
      return cep ? cep.replace('-', '') : ''
    },
    removerFaixaCep(faixa) {
      this.form.faixasCep = (this.form.faixasCep || []).filter(f => {
        return f.inicial !== faixa.inicial && f.final !== faixa.final
      })
    },
    adicionarFaixaCep() {
      const cepInicial = this.clearCep(this.form.cep_inicial)
      const cepFinal = this.clearCep(this.form.cep_final)
      if (!cepInicial || !cepFinal)
        return this.$vueOnToast.pop('error', 'Informe o CEP inicial e final')
      if (Number(cepFinal) < Number(cepInicial))
        return this.$vueOnToast.pop(
          'error',
          'O CEP final é menor que o CEP inicial'
        )
      if (this.cepValidation(cepInicial))
        return this.$vueOnToast.pop('error', 'CEP inicial inválido')
      if (this.cepValidation(cepFinal))
        return this.$vueOnToast.pop('error', 'CEP final inválido')
      let novaFaixa = {
        inicial: Number(cepInicial),
        final: Number(cepFinal)
      }
      let faixasCoincidem = this.form.faixasCep.filter(
        faixaExistente =>
          (Number(faixaExistente.inicial) <= novaFaixa.inicial &&
            novaFaixa.inicial <= Number(faixaExistente.final)) ||
          (Number(faixaExistente.final) >= novaFaixa.final &&
            novaFaixa.final >= Number(faixaExistente.inicial))
      )
      if (faixasCoincidem.length > 0) {
        return this.$vueOnToast.pop(
          'error',
          'Essa faixa de CEP coincide com outra desta tabela de preço'
        )
      }
      this.isLoading = true
      this.$store
        .dispatch(GRUPO_PRECO_VALIDA_CEP, {
          codigoGrupo: this.form.codigo_grupo_preco,
          novaFaixa: novaFaixa
        })
        .then(() => {
          this.form.faixasCep.push({ inicial: cepInicial, final: cepFinal }) //remover
          this.form.cep_inicial = ''
          this.form.cep_final = ''
          this.isLoading = false
        })
        .catch(err => {
          this.$vueOnToast.pop(
            'error',
            'Faixa de CEP inválida: ' + err.responseJSON.message
          )
          this.isLoading = false
        })
    },
    updatePajeAvailability(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idcadastroextraproduto]: true
      }
      this.isLoading = true
      const data = {
        disponivel: `${!item.disponivelvenda}`,
        idproduto: item.idproduto,
        idcadastroextraproduto: item.idcadastroextraproduto,
        codigoGrupoPreco: this.form.codigo_grupo_preco
      }
      this.$store
        .dispatch(TABELA_PRECO_UPDATE_DISPONIVEL_VENDA, data)
        .then(() => {
          this.isLoading = false
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
          this.$vueOnToast.pop(
            'success',
            `Disponibilidade do produto ${item.nome} alterada com sucesso`
          )
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha atualizar disponibilidade do produto'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
        })
    },
    updateRemotePrices(item) {
      //const value = parseFloat(event)
      this.loadingRow = {
        ...this.loadingRow,
        [item.idcadastroextraproduto]: true
      }
      const data = {
        ...item,
        codigoGrupoPreco: this.form.codigo_grupo_preco,
        idgrupocliente: this.form.codigo_grupo_preco,
        idprodutoerp: item.codigointerno,
        precovenda: item.precovenda || 0,
        maxporpedido: item.maxporpedido || 0,
        precovendaatacado: item.precovendaatacado || 0,
        qtdevendaatacado: item.minvendaatacado || 0
      }
      this.isLoading = true
      this.$store
        .dispatch(TABELA_PRECO_UPDATE_PRECOS, data)
        .then(() => {
          this.isLoading = false
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
          this.$vueOnToast.pop(
            'success',
            `Preço do produto ${item.nome} alterada com sucesso`
          )
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha atualizar preço do produto'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    disableCheckbox(item) {
      const {
        maxporpedido,
        minvendaatacado,
        precovenda,
        precovendaatacado
      } = item
      if (maxporpedido || minvendaatacado || precovenda || precovendaatacado) {
        return false
      }
      return true
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    title() {
      switch (this.mode) {
        case 'produto':
          return 'Produtos vínculados'
        case 'cep':
          return 'Editar faixa de CEP'
        default:
          return 'Clientes vínculados'
      }
    },
    usesDelegatedPriceStorage() {
      return this.getFornecedorTag['uses-delegated-price-storage']
    },
    endpointClient() {
      return `/api/v4/client/datatable/${this.getFornecedorId}?grupoSelecionado=${this.form.codigo_grupo_preco}`
    },
    endpointProduct() {
      return `/api/v4/tabela-preco/${this.form.codigo_grupo_preco}/fornecedor/${this.getFornecedorId}/datatable`
    }
  },
  created() {
    const { codigo_grupo_preco, vinculo } = this.$route.params
    this.form.codigo_grupo_preco = codigo_grupo_preco
    switch (vinculo) {
      case 'cliente':
        this.mode = 'cliente'
        break
      case 'produto':
        this.mode = 'produto'
        break
      case 'cep':
        this.mode = 'cep'
        this.getDataFaixaCep(codigo_grupo_preco)
        break
      default:
        this.onClose()
    }
  },
  mounted() {},
  watch: {}
}
</script>

<style></style>
